@font-face {
  font-family: "ciq-icon-set";
  src: url("ciq-icon-set.eot?cgbhpc");
  src: url("ciq-icon-set.eot?cgbhpc#iefix") format("embedded-opentype"),
    url("ciq-icon-set.ttf?cgbhpc") format("truetype"),
    url("ciq-icon-set.woff?cgbhpc") format("woff"),
    url("ciq-icon-set.svg?cgbhpc#ciq-icon-set") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="ciq-"],
[class*=" ciq-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  /* biome-ignore lint/a11y/useGenericFontNames: icons */
  font-family: "ciq-icon-set" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ciq-component-icon-ic-close-circle:before {
  content: "\e941";
}
.ciq-component-icon-ic-connection-low:before {
  content: "\e942";
}
.ciq-icon-ic-copy:before {
  content: "\e93d";
}
.ciq-icon-ic-on-phone:before {
  content: "\e93e";
}
.ciq-icon-ic-on-phone-dial-in:before {
  content: "\e93f";
}
.ciq-icon-ic-up:before {
  content: "\e940";
}
.ciq-ic-info:before {
  content: "\e93c";
}
.ciq-exclamation-circle-solid:before {
  content: "\e93b";
}
.ciq-component-icon-ic-on-phone:before {
  content: "\e93a";
}
.ciq-xls .path1:before {
  content: "\e910";
  color: rgb(0, 172, 84);
}
.ciq-xls .path2:before {
  content: "\e911";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-xls .path3:before {
  content: "\e912";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-xls .path4:before {
  content: "\e913";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-xls .path5:before {
  content: "\e914";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-zip .path1:before {
  content: "\e915";
  color: rgb(124, 129, 156);
}
.ciq-zip .path2:before {
  content: "\e916";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-zip .path3:before {
  content: "\e917";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-zip .path4:before {
  content: "\e918";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-zip .path5:before {
  content: "\e919";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-docs-calendar .path1:before {
  content: "\e91a";
  color: rgb(97, 87, 252);
}
.ciq-docs-calendar .path2:before {
  content: "\e91b";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-docs-calendar .path3:before {
  content: "\e91c";
  margin-left: -0.75em;
  color: rgb(255, 255, 255);
}
.ciq-csv .path1:before {
  content: "\e91d";
  color: rgb(0, 172, 84);
}
.ciq-csv .path2:before {
  content: "\e91e";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-csv .path3:before {
  content: "\e91f";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-csv .path4:before {
  content: "\e920";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-csv .path5:before {
  content: "\e921";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-doc-generic .path1:before {
  content: "\e922";
  color: rgb(0, 98, 255);
}
.ciq-doc-generic .path2:before {
  content: "\e923";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-doc-generic .path3:before {
  content: "\e924";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-doc-word .path1:before {
  content: "\e925";
  color: rgb(0, 98, 255);
}
.ciq-doc-word .path2:before {
  content: "\e926";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-doc-word .path3:before {
  content: "\e927";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-doc-word .path4:before {
  content: "\e928";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-doc-word .path5:before {
  content: "\e929";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-excel .path1:before {
  content: "\e92a";
  color: rgb(0, 172, 84);
}
.ciq-excel .path2:before {
  content: "\e92b";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-excel .path3:before {
  content: "\e92c";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-image .path1:before {
  content: "\e92d";
  color: rgb(0, 171, 84);
}
.ciq-image .path2:before {
  content: "\e92e";
  margin-left: -0.7470703125em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-image .path3:before {
  content: "\e92f";
  margin-left: -0.7470703125em;
  color: rgb(255, 255, 255);
}
.ciq-image .path4:before {
  content: "\e930";
  margin-left: -0.7470703125em;
  color: rgb(255, 255, 255);
}
.ciq-pdf .path1:before {
  content: "\e931";
  color: rgb(245, 54, 54);
}
.ciq-pdf .path2:before {
  content: "\e932";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-pdf .path3:before {
  content: "\e933";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-pdf .path4:before {
  content: "\e934";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-pdf .path5:before {
  content: "\e935";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-presentation .path1:before {
  content: "\e936";
  color: rgb(245, 54, 54);
}
.ciq-presentation .path2:before {
  content: "\e937";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
  opacity: 0.5;
}
.ciq-presentation .path3:before {
  content: "\e938";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-presentation .path4:before {
  content: "\e939";
  margin-left: -0.740234375em;
  color: rgb(255, 255, 255);
}
.ciq-chevron-up-down:before {
  content: "\e90f";
}
.ciq-component-icon-ic-place:before {
  content: "\e90c";
}
.ciq-activity:before {
  content: "\e90d";
}
.ciq-calender:before {
  content: "\e90e";
}
.ciq-check:before {
  content: "\e90b";
}
.ciq-push-pin-off:before {
  content: "\e90a";
}
.ciq-push-pin:before {
  content: "\e909";
}
.ciq-close:before {
  content: "\e900";
}
.ciq-call-audio-off:before {
  content: "\e901";
}
.ciq-call-video-off:before {
  content: "\e902";
}
.ciq-people:before {
  content: "\e903";
}
.ciq-call-end:before {
  content: "\e904";
}
.ciq-call-audio-on:before {
  content: "\e905";
}
.ciq-call-video-on:before {
  content: "\e906";
}
.ciq-call-present:before {
  content: "\e907";
}
.ciq-call-settings:before {
  content: "\e908";
}
